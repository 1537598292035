import React from "react"
import { Box, Heading, Text } from "@chakra-ui/react"
import Layout from "../components/layout"

const AboutPage = () => (
  <Layout>
    <Heading as="h1" mb={4}>
      About Capybara Visions
    </Heading>
    <Text mb={4}>
      Capybara Visions is an artistic project exploring the boundaries of visual creativity. Learn more about our journey and mission.
    </Text>
  </Layout>
)

export default AboutPage
